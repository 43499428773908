import { nanoid } from 'nanoid';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Flex } from '@once/components/Layout/Flex';
import { Text } from '@once/components/Primitive/Text';
import { NotificationEvent, ClearNotificationEvent } from '@once/events';
import { CommonProps } from '@once/types';
import { CloseIcon } from '../../icons';
import {
  NotificationBadge,
  NotificationContainer,
  NotificationsWrapper,
  Timer,
  TimerContainer,
} from './Notifications.styles';

type ColorConfig = {
  bgColor: string;
  textColor: string;
  icon: CommonProps.Component<CommonProps.Icon.SimpleColor>;
};

type Notification = {
  id: string;
  data: { title: string; description?: string };
  config: ColorConfig;
  delay: number;
};

type NotifProps = {
  bgColor: string;
  notification: Notification;
  removeNotification: (id: string) => void;
};

export function Notif({ bgColor, notification, removeNotification }: NotifProps): JSX.Element {
  const [fading, setFading] = useState(false);
  const elem = useRef<HTMLDivElement>(null);

  useEffect(
    function handleFade() {
      const timerID = setTimeout(() => {
        setFading(true);
      }, notification.delay);
      return () => clearTimeout(timerID);
    },
    [notification.delay],
  );

  useEffect(() => {
    function handleTransitionEnd(): void {
      removeNotification(notification.id);
    }

    const elm = elem.current;
    if (elm) {
      elm.addEventListener('transitionend', handleTransitionEnd);
      return () => elm.removeEventListener('transitionend', handleTransitionEnd);
    }
    return undefined;
  }, [notification.id, removeNotification]);

  return (
    <NotificationContainer
      ref={elem}
      data-fading={fading}
      $textColor={notification.config.textColor}
      $bgColor={bgColor}
      onClick={() => removeNotification(notification.id)}
    >
      <TimerContainer />
      <Timer $duration={notification.delay} $color={notification.config.bgColor} />

      <Flex direction="row" alignItems="flex-start" justifyContent="space-between">
        <Flex direction="row" alignItems="center" gap={12}>
          <NotificationBadge $bgColor={notification.config.bgColor}>
            <notification.config.icon.data {...notification.config.icon.props} width={18} />
          </NotificationBadge>
          <Text size="base_highlight" color="var(--white-color)">
            {notification.data.title}{' '}
            {notification.data.description ? (
              <Text as="span" size="base" color="var(--white-color)">
                {notification.data.description}
              </Text>
            ) : null}
          </Text>
        </Flex>
        <CloseIcon
          mainColor="var(--text-secondary-color)"
          width={32}
          onClick={() => removeNotification(notification.id)}
        />
      </Flex>
    </NotificationContainer>
  );
}

type NotificationHandlerProps = {
  bgColor: string;
  colorConfig: {
    success: ColorConfig;
    info: ColorConfig;
    error: ColorConfig;
  };
};

export function NotificationsHandler({ bgColor, colorConfig }: NotificationHandlerProps): JSX.Element {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const removeNotification = useCallback(
    (id: string): void => setNotifications((notifs) => notifs.filter((n) => n.id !== id)),
    [setNotifications],
  );

  useEffect(
    function listenForNewNotifications() {
      function handleNewNotification(e: Event): void {
        if (e instanceof NotificationEvent) {
          const id = nanoid();
          setNotifications((n) =>
            n.concat({
              id: e.detail.id ?? id,
              data: e.detail.data,
              config: colorConfig[e.detail.type],
              delay: e.detail.delay ?? 2500,
            }),
          );
        }
      }

      document.addEventListener(NotificationEvent.eventName, handleNewNotification);
      return () => document.removeEventListener(NotificationEvent.eventName, handleNewNotification);
    },
    [colorConfig, setNotifications],
  );

  useEffect(
    function handleClearNotification() {
      function handleClear(e: Event): void {
        if (e instanceof ClearNotificationEvent) {
          removeNotification(e.detail.id);
        }
      }

      document.addEventListener(ClearNotificationEvent.eventName, handleClear);
      return () => document.removeEventListener(ClearNotificationEvent.eventName, handleClear);
    },
    [removeNotification],
  );

  return (
    <NotificationsWrapper>
      {notifications.map((notif) => (
        <Notif bgColor={bgColor} key={notif.id} notification={notif} removeNotification={removeNotification} />
      ))}
    </NotificationsWrapper>
  );
}
