import { ImageLoader } from 'next/image';

// const safeURL = (url: string): string =>
//   typeof btoa === 'function' ? btoa(url).replace(/\//g, '_') : Buffer.from(url).toString('base64').replace(/\//g, '_');

export const imageLoader: ImageLoader | undefined =
  // process.env.NODE_ENV === 'production'
  // ? ({ width, src }) => `/api/_medias/scale/${safeURL(src)}.webp?w=${width}`
  // : undefined;
  undefined;
