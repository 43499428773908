import gql from 'graphql-tag';

import { BigCommerceFullMetafields } from '../fragments';

export const BIG_COMMERCE_METAFIELDS_BY_COLLECTION_ID_QUERY = gql`
  query bigCommerceMetafieldsCollectionByID($id: ID!, $namespace: String!, $keys: [String!] = []) {
    node(id: $id) {
      __typename
      ... on Category {
        metafields(namespace: $namespace, keys: $keys) {
          edges {
            node {
              ...BigCommerceFullMetafields
            }
          }
        }
      }
    }
  }
  ${BigCommerceFullMetafields}
`;

export const BIG_COMMERCE_METAFIELDS_BY_PRODUCT_HANDLE_QUERY = gql`
  query bigCommerceMetafieldsByProductHandle($path: String!, $namespace: String!, $keys: [String!] = []) {
    site {
      route(path: $path) {
        node {
          __typename
          ... on Product {
            metafields(namespace: $namespace, keys: $keys) {
              edges {
                node {
                  ...BigCommerceFullMetafields
                }
              }
            }
          }
        }
      }
    }
  }
  ${BigCommerceFullMetafields}
`;

export const BIG_COMMERCE_METAFIELDS_BY_PRODUCT_ID_QUERY = gql`
  query bigCommerceMetafieldsByProductID($id: ID!, $namespace: String!, $keys: [String!] = []) {
    site {
      product(id: $id) {
        __typename
        metafields(namespace: $namespace, keys: $keys) {
          edges {
            node {
              ...BigCommerceFullMetafields
            }
          }
        }
      }
    }
  }
  ${BigCommerceFullMetafields}
`;

export const BIG_COMMERCE_METAFIELDS_BY_VARIANT_ID_QUERY = gql`
  query bigCommerceMetafieldsByVariantID($id: ID!, $namespace: String!, $keys: [String!] = []) {
    node(id: $id) {
      __typename
      ... on Variant {
        metafields(namespace: $namespace, keys: $keys) {
          edges {
            node {
              ...BigCommerceFullMetafields
            }
          }
        }
      }
    }
  }
  ${BigCommerceFullMetafields}
`;

export const BIG_COMMERCE_VARIANT_METAFIELDS_BY_PRODUCT_HANDLE_QUERY = gql`
  query bigCommerceVariantMetafieldsByProductHandle($path: String!, $namespace: String!, $keys: [String!] = []) {
    site {
      route(path: $path) {
        node {
          __typename
          ... on Product {
            variants {
              edges {
                node {
                  metafields(namespace: $namespace, keys: $keys) {
                    edges {
                      node {
                        ...BigCommerceFullMetafields
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${BigCommerceFullMetafields}
`;

export const BIG_COMMERCE_VARIANT_METAFIELDS_BY_PRODUCT_ID_QUERY = gql`
  query bigCommerceVariantMetafieldsByProductID($id: ID!, $namespace: String!, $keys: [String!] = []) {
    site {
      product(id: $id) {
        __typename
        variants {
          edges {
            __typename
            node {
              metafields(namespace: $namespace, keys: $keys) {
                edges {
                  node {
                    ...BigCommerceFullMetafields
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${BigCommerceFullMetafields}
`;
