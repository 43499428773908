import { atom, Atom, PrimitiveAtom, useAtom, useAtomValue } from 'jotai';
import { atomFamily, atomWithStorage, useAtomCallback } from 'jotai/utils';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import useSWR from 'swr';

import { useGetCommerceProvider } from '@once/data/client/useGetCommerceProvider';
import { CustomerLoginEvent, CustomerSignupEvent } from '@once/events';
import { Storefront } from '@once/types';

import {
  CUSTOMER_ACCESS_TOKEN_CREATE_MUTATION,
  CUSTOMER_ACCESS_TOKEN_RENEW_MUTATION,
  CUSTOMER_ACTIVATE_MUTATION,
  CUSTOMER_RECOVER_MUTATION,
  CUSTOMER_RESET_BY_URL_MUTATION,
} from '../common/shopify/mutations';
import { normalizeCustomer } from '../common/shopify/normalize/customer';
import { CUSTOMER_QUERY } from '../common/shopify/queries';
import {
  CustomerAccessTokenCreateMutation,
  CustomerAccessTokenCreateMutationVariables,
  CustomerAccessTokenRenewMutation,
  CustomerAccessTokenRenewMutationVariables,
  CustomerActivateMutation,
  CustomerActivateMutationVariables,
  CustomerQuery,
  CustomerQueryVariables,
  CustomerRecoverMutation,
  CustomerRecoverMutationVariables,
  CustomerResetByUrlMutation,
  CustomerResetByUrlMutationVariables,
} from '../common/shopify/schema';
import { shopifyFetch } from './fetch';
import { getCommerceProvider } from './fetchConfig';
// import { getCommerceProvider } from './fetchConfig';

const DEFAULT_LOCALE = '';
const TWO_WEEKS = 1000 * 60 * 60 * 24 * 14;

type Credentials = {
  accessToken: string;
  expiresAt: string; // Date
};

const CredentialsAtom = atomFamily<string, PrimitiveAtom<Credentials | null>>((locale) =>
  atomWithStorage<Credentials | null>(`customerCredentials_${locale}`, null),
);

export const CustomerAccessTokenAtom = atomFamily<string, Atom<string | null>>((locale) =>
  atom<string | null>((get) => {
    const credentials = get(CredentialsAtom(locale));
    if (!credentials || new Date(credentials.expiresAt) < new Date()) {
      return null;
    }
    return credentials.accessToken;
  }),
);

const CustomerAtom = atomFamily<string, PrimitiveAtom<Storefront.Customer | null>>(() =>
  atom<Storefront.Customer | null>(null),
);

type LoginCredentials = {
  email: string;
  password: string;
};

function useRenewToken(): (accessToken: string) => Promise<void> {
  const { locale = DEFAULT_LOCALE } = useRouter();
  const commerceProvider = useGetCommerceProvider(locale);

  return useAtomCallback(async (get, set, accessToken) => {
    switch (commerceProvider?.type) {
      case 'SHOPIFY': {
        const res = await shopifyFetch<CustomerAccessTokenRenewMutation, CustomerAccessTokenRenewMutationVariables>(
          CUSTOMER_ACCESS_TOKEN_RENEW_MUTATION,
          {
            accessToken,
          },
          commerceProvider.fetchConfig,
        );

        if (res?.customerAccessTokenRenew?.customerAccessToken) {
          set(CredentialsAtom(locale), {
            accessToken: res.customerAccessTokenRenew.customerAccessToken.accessToken,
            expiresAt: res.customerAccessTokenRenew.customerAccessToken.expiresAt,
          });
        }
        break;
      }
      default:
        throw new Error('Commerce data provider is not handled yet in "client/customer#useRenewToken"');
    }
  });
}

async function customerFetcher(locale: string | undefined, accessToken: string): Promise<Storefront.Customer | null> {
  const commerceProvider = await getCommerceProvider(locale);

  switch (commerceProvider?.type) {
    case 'SHOPIFY': {
      const res = await shopifyFetch<CustomerQuery, CustomerQueryVariables>(
        CUSTOMER_QUERY,
        {
          accessToken,
        },
        commerceProvider.fetchConfig,
      );

      if (!res?.customer) {
        return null;
      }

      return normalizeCustomer(res.customer);
    }
    default:
      throw new Error('Commerce data provider is not handled yet in "client/customer#customerFetcher"');
  }
}

export function useCustomer(): Storefront.Customer | null {
  const { locale = DEFAULT_LOCALE } = useRouter();
  const credentials = useAtomValue(CredentialsAtom(locale));
  const renewToken = useRenewToken();
  const credentialsExpirationDate = credentials ? Date.parse(credentials.expiresAt) : 0;
  const accessToken = credentialsExpirationDate < Date.now() ? null : credentials?.accessToken;
  const [customer, setCustomer] = useAtom(CustomerAtom(locale));

  const renewedToken = useRef(false);
  useEffect(
    function handleRenewToken() {
      if (accessToken && credentialsExpirationDate < Date.now() + TWO_WEEKS && !renewedToken.current) {
        renewedToken.current = true;
        renewToken(accessToken);
      }
    },
    [accessToken, credentialsExpirationDate, renewToken],
  );

  const { data } = useSWR(!accessToken || !!customer ? null : [locale, accessToken, 'customer'], customerFetcher);
  useEffect(() => {
    if (data) {
      setCustomer(data);
    }
  }, [data, setCustomer]);

  return customer;
}

export function useIsLoggedIn(): boolean {
  const { locale = DEFAULT_LOCALE } = useRouter();
  const accessToken = useAtomValue(CustomerAccessTokenAtom(locale));

  return !!accessToken;
}

export function useLogin(): (credentials: LoginCredentials) => Promise<boolean> {
  const { locale = DEFAULT_LOCALE } = useRouter();

  const commerceProvider = useGetCommerceProvider(locale);
  return useAtomCallback<boolean, LoginCredentials>(async (get, set, { email, password }) => {
    switch (commerceProvider?.type) {
      case 'SHOPIFY': {
        const res = await shopifyFetch<CustomerAccessTokenCreateMutation, CustomerAccessTokenCreateMutationVariables>(
          CUSTOMER_ACCESS_TOKEN_CREATE_MUTATION,
          {
            input: { email, password },
          },
          commerceProvider.fetchConfig,
        );

        if (res?.customerAccessTokenCreate?.customerAccessToken) {
          set(CredentialsAtom(locale), {
            accessToken: res.customerAccessTokenCreate.customerAccessToken.accessToken,
            expiresAt: res.customerAccessTokenCreate.customerAccessToken.expiresAt,
          });
          document.dispatchEvent(new CustomerLoginEvent());
          return true;
        }
        return false;
      }
      default:
        throw new Error('Commerce data provider is not handled yet in "client/customer#useLogin"');
    }
  });
}

export function useLogout(): () => Promise<void> {
  const { locale = DEFAULT_LOCALE } = useRouter();

  return useAtomCallback(async (_, set) => {
    set(CredentialsAtom(locale), null);
  });
}

type VerifyEmailResponse =
  | {
      state: 'NOT_FOUND' | 'ACTIVE';
    }
  | {
      state: 'INACTIVE';
      id: string;
      activationToken: string;
    };

export async function verifyEmail(email: string): Promise<VerifyEmailResponse> {
  const response = await fetch('/api/recharge/verify-email', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email }),
  });

  return response.json();
}

export function useRecoverCustomer(): (email: string) => Promise<void> {
  const { locale = DEFAULT_LOCALE } = useRouter();
  const commerceProvider = useGetCommerceProvider(locale);

  return useAtomCallback(async (get, set, email) => {
    switch (commerceProvider?.type) {
      case 'SHOPIFY':
        // It just sends an email to the user
        await shopifyFetch<CustomerRecoverMutation, CustomerRecoverMutationVariables>(
          CUSTOMER_RECOVER_MUTATION,
          {
            email,
          },
          commerceProvider.fetchConfig,
        );
        break;
      default:
        throw new Error('Commerce data provider is not handled yet in "client/customer#useRecoverCustomer"');
    }
  });
}

type ResetPasswordParams = {
  url: string;
  password: string;
};

export function useResetPassword(): (params: ResetPasswordParams) => Promise<void> {
  const { locale = DEFAULT_LOCALE } = useRouter();
  const commerceProvider = useGetCommerceProvider(locale);
  // getCommerceProvider(locale);

  return useAtomCallback(async (get, set, { url, password }) => {
    switch (commerceProvider?.type) {
      case 'SHOPIFY': {
        const res = await shopifyFetch<CustomerResetByUrlMutation, CustomerResetByUrlMutationVariables>(
          CUSTOMER_RESET_BY_URL_MUTATION,
          {
            url,
            password,
          },
          commerceProvider.fetchConfig,
        );

        if (res?.customerResetByUrl?.customerAccessToken) {
          set(CredentialsAtom(locale), {
            accessToken: res.customerResetByUrl.customerAccessToken.accessToken,
            expiresAt: res.customerResetByUrl.customerAccessToken.expiresAt,
          });
        }
        break;
      }
      default:
        throw new Error('Commerce data provider is not handled yet in "client/customer#useResetPassword"');
    }
  });
}

type CustomerActivationParams = {
  customerID: string;
  token: string;
  password: string;
};

export function useActivateCustomer(): (params: CustomerActivationParams) => void {
  const { locale = DEFAULT_LOCALE } = useRouter();
  const commerceProvider = useGetCommerceProvider(locale);
  // getCommerceProvider(locale);

  return useAtomCallback(async (get, set, { customerID, token, password }) => {
    switch (commerceProvider?.type) {
      case 'SHOPIFY': {
        const res = await shopifyFetch<CustomerActivateMutation, CustomerActivateMutationVariables>(
          CUSTOMER_ACTIVATE_MUTATION,
          {
            id: customerID,
            input: { activationToken: token, password },
          },
          commerceProvider.fetchConfig,
        );

        if (res?.customerActivate?.customerAccessToken) {
          set(CredentialsAtom(locale), {
            accessToken: res.customerActivate.customerAccessToken.accessToken,
            expiresAt: res.customerActivate.customerAccessToken.expiresAt,
          });
          document.dispatchEvent(new CustomerSignupEvent());
        }
        break;
      }
      default:
        throw new Error('Commerce data provider is not handled yet in "client/customer#useActivateCustomer"');
    }
  });
}
