import gql from 'graphql-tag';

import { FullMetafield } from '../fragments';

export const METAFIELD_BY_COLLECTION_ID_QUERY = gql`
  query metafieldByCollectionID($id: ID!, $namespace: String!, $key: String!) {
    collection(id: $id) {
      title
      metafield(namespace: $namespace, key: $key) {
        ...FullMetafield
      }
    }
  }
  ${FullMetafield}
`;

export const METAFIELD_BY_PRODUCT_HANDLE_QUERY = gql`
  query metafieldByProductHandle($handle: String!, $namespace: String!, $key: String!) {
    product(handle: $handle) {
      metafield(namespace: $namespace, key: $key) {
        ...FullMetafield
      }
    }
  }
  ${FullMetafield}
`;

export const METAFIELD_BY_PRODUCT_ID_QUERY = gql`
  query metafieldByProductID($id: ID!, $namespace: String!, $key: String!) {
    product(id: $id) {
      metafield(namespace: $namespace, key: $key) {
        ...FullMetafield
      }
    }
  }
  ${FullMetafield}
`;

export const METAFIELD_BY_VARIANT_ID_QUERY = gql`
  query metafieldByVariantID($id: ID!, $namespace: String!, $key: String!) {
    node(id: $id) {
      __typename
      ... on ProductVariant {
        metafield(namespace: $namespace, key: $key) {
          ...FullMetafield
        }
      }
    }
  }
  ${FullMetafield}
`;

export const VARIANT_METAFIELD_BY_PRODUCT_HANDLE_QUERY = gql`
  query variantMetafieldByProductHandle($handle: String!, $namespace: String!, $key: String!) {
    product(handle: $handle) {
      variants(first: 250) {
        edges {
          node {
            id
            metafield(namespace: $namespace, key: $key) {
              ...FullMetafield
            }
          }
        }
      }
    }
  }
  ${FullMetafield}
`;

export const VARIANT_METAFIELD_BY_PRODUCT_ID_QUERY = gql`
  query variantMetafieldByProductID($id: ID!, $namespace: String!, $key: String!) {
    product(id: $id) {
      variants(first: 250) {
        edges {
          node {
            id
            metafield(namespace: $namespace, key: $key) {
              ...FullMetafield
            }
          }
        }
      }
    }
  }
  ${FullMetafield}
`;
