/* eslint-disable max-classes-per-file */

/**
 * /!\ UGLY TRICK, BUT REQUIRED SINCE CustomEvent DOES NOT EXIST IN THE SERVER-SIDE CONTEXT.
 */
if (typeof global.CustomEvent !== 'function') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  global.CustomEvent = class FakeCustomEvent {};
}

type TabGoToEventData = {
  position: number;
};

export class TabSetPositionEvent extends CustomEvent<TabGoToEventData> {
  static eventName = (tabID: string): string => `once:${tabID}:tab-set-position`;

  constructor(tabID: string, data: TabGoToEventData) {
    super(TabSetPositionEvent.eventName(tabID), {
      detail: data,
      cancelable: false,
    });
  }
}

type TabCurrentPositionEventData = {
  position: number;
};

export class TabSignalPositionEvent extends CustomEvent<TabCurrentPositionEventData> {
  static eventName = (tabID: string): string => `once:${tabID}:tab-signal-position`;

  constructor(tabID: string, data: TabGoToEventData) {
    super(TabSignalPositionEvent.eventName(tabID), {
      detail: data,
      cancelable: false,
    });
  }
}
