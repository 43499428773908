/* eslint-disable max-classes-per-file */

/**
 * /!\ UGLY TRICK, BUT REQUIRED SINCE CustomEvent DOES NOT EXIST IN THE SERVER-SIDE CONTEXT.
 */
if (typeof global.CustomEvent !== 'function') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  global.CustomEvent = class FakeCustomEvent {};
}

/**
 * HeaderSetOpaque
 */
export type HeaderSetOpaqueData = {
  opaque: boolean;
};

export class HeaderSetOpaqueEvent extends CustomEvent<HeaderSetOpaqueData> {
  static eventName = 'once-header:opaque';

  constructor(data: HeaderSetOpaqueData) {
    super(HeaderSetOpaqueEvent.eventName, {
      detail: data,
      cancelable: false,
    });
  }
}
