import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useRef } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import 'normalize.css';
import '../public/globals.css';

import { CheckmarkIcon, InfoIcon, WarningIcon } from '@once/components/icons';
import { NotificationsHandler } from '@once/components/Template/NotificationsHandler';
import { gtmPushEvent } from '@once/data/client';
import { HeaderSetOpaqueEvent, ScrollToTopEvent } from '@once/events';
import { useInitMobileDelta } from '@once/hooks/useInitMobileDelta';
import { useMQL } from '@once/hooks/useMQL';
import { useWindowSize } from '@once/hooks/useWindowSize';
import { Config } from '@once/types';
// import { useElevarEvents, useElevarTrackLastCollectionSeen } from '@once/data/client/elevar';

const makeFontFace = (font: Config.Font): ReturnType<typeof css> => css`
  @font-face {
    font-family: '${font.name.replace(/'/g, '')}';
    src: url(${font.url}) format('${font.format}');
    font-weight: ${font.weight};
    font-style: ${font.italic ? 'italic' : 'normal'};
    font-display: swap;
  }
`;

const fonts: Config.Font[] = [];
const googleFonts: string[] = [];

const DEFAULT_FONT_FAMILY = '';
const GTM_ID = 'GTM-TZLWKCK';

const GlobalStyle = createGlobalStyle`
  :root {
    --light: #FFFEFC;
    --dark: #171717;
    --medium-dark: #212121;
    --beige: #F4F3EE;
    --light-beige: #FFFEFC;
    --white: #FFFFFF;
    --purple-rain: #6D73FF;
    --acid-citric: #D7EE79;
    --cashmere-blue: #CAE2E6;
    --komorebi: #40413A;
    --greige: #CFD0C5;
    --primary-color: var(--light);
    --text-primary-color: var(--dark);
    --secondary-color: var(--medium-dark);
    --text-secondary-color: var(--medium-dark);
    --text-color: var(--dark);
    font-size: 16px;
  }

  body {
    font-family: ${DEFAULT_FONT_FAMILY}, sans-serif;
    margin: 0;
    position: fixed;
    overflow: hidden;
    width: 100%;
  }
  

  ${fonts.map(makeFontFace)}
`;

const fontsToPreload = fonts.filter((f) => f.preload);

const AppWrapper = styled.div`
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
`;

type PageWrapperProps = {
  'data-snap': boolean;
  'data-hidescrollbar': boolean;
  'data-nonscrollable': boolean;
};

const PageWrapper = styled.div<PageWrapperProps>`
  width: 100%;
  height: calc(100vh - var(--mobile-delta));
  position: relative;
  background: #ffffff;
  overflow-x: hidden;
  overflow-y: auto;

  &[data-snap='true'] {
    overflow-y: hidden;
  }

  &[data-hidescrollbar='true'] {
    scrollbar-width: none;
  }
  &[data-hidescrollbar='true']::-webkit-scrollbar {
    display: none;
  }

  &[data-nonscrollable='true'] {
    overflow-y: hidden;
  }
`;

export default function App({ Component, pageProps }: AppProps): JSX.Element {
  const pageConfig: Config.Page | undefined = pageProps.config;

  const heightRef = useRef<HTMLDivElement>(null);
  useInitMobileDelta(heightRef);
  useMQL();
  useWindowSize();

  // Force scroll top on router.push
  const pageRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  useEffect(() => {
    function scrollToTop(): void {
      pageRef.current?.scrollTo(0, 0);
      document.dispatchEvent(new ScrollToTopEvent());
    }
    router.events.on('routeChangeComplete', scrollToTop);
    return () => router.events.off('routeChangeComplete', scrollToTop);
  }, [router.events]);

  // Send pageview events to GTM
  useEffect(() => {
    function handleRouteChange(page: string): void {
      gtmPushEvent({ event: 'pageview', page });
    }

    if (GTM_ID) {
      router.events.on('routeChangeComplete', handleRouteChange);
      return () => router.events.off('routeChangeComplete', handleRouteChange);
    }
    return undefined;
  }, [router.events]);

  // Uncomment this block if you're using elevar
  /*
  useElevarTrackLastCollectionSeen(['/collections/all']);
  useElevarEvents();
  */

  useEffect(() => {
    function handleScroll(this: HTMLDivElement): void {
      requestAnimationFrame(() => {
        // eslint-disable-next-line react/no-this-in-sfc
        document.dispatchEvent(new HeaderSetOpaqueEvent({ opaque: this.scrollTop > 0 }));
      });
    }

    if (pageConfig?.header.opaqueOnScroll) {
      const ref = pageRef.current;
      ref?.addEventListener('scroll', handleScroll, { passive: true });
      return () => ref?.removeEventListener('scroll', handleScroll);
    }
    return undefined;
  }, [pageConfig?.header.opaqueOnScroll]);

  if (!pageConfig) {
    // Should never happen in prod, only useful for dev to remember to set a page config
    return (
      <div>
        Page config is missing, run{' '}
        <code style={{ fontSize: '1.2rem', color: '#b22222' }}>npm run once:createPage</code> to create a new one!
      </div>
    );
  }

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
        <link rel="icon" href={pageConfig.head.faviconURL} />
        <title>{pageConfig.head.title}</title>
        <meta name="description" content={pageConfig.head.description} />

        <meta name="og:site_name" content="Once.app" />
        <meta name="og:title" content={pageConfig.head.title} />
        <meta name="og:type" content="website" />
        <meta name="og:description" content={pageConfig.head.description} />
        <meta name="og:image" content={pageConfig.head.ogImage} />
        <meta name="og:image:alt" content={pageConfig.head.ogImageAlt} />
        <meta name="og:image:width" content={`${pageConfig.head.ogImageWidth}`} />
        <meta name="og:image:height" content={`${pageConfig.head.ogImageHeight}`} />

        <meta name="twitter:card" content={pageConfig.head.twitterCard} />
        <meta name="twitter:title" content={pageConfig.head.title} />
        <meta name="twitter:description" content={pageConfig.head.description} />
        {pageConfig.head.twitterSite ? <meta name="twitter:site" content={pageConfig.head.twitterSite} /> : null}
        {pageConfig.head.twitterCreator ? (
          <meta name="twitter:creator" content={pageConfig.head.twitterCreator} />
        ) : null}

        {fontsToPreload.map((font) => (
          <link
            key={`preload_${font.url}`}
            rel="preload"
            href={font.url}
            as="font"
            type={`font/${font.format}`}
            crossOrigin=""
          />
        ))}
        {googleFonts.length > 0 ? (
          <>
            <link key="preconnect_googleapis" rel="preconnect" href="https://fonts.googleapis.com" />
            <link key="preconnect_gstatic" rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
          </>
        ) : null}
        {googleFonts.map((fontURL) => (
          <link key={`googleFont_${fontURL}`} href={fontURL} rel="stylesheet" />
        ))}
      </Head>

      <GlobalStyle />

      {/* 3rd party scripts */}
      {GTM_ID ? (
        <>
          <Script src={`https://www.googletagmanager.com/gtm.js?id=${GTM_ID}&l=gtm`} />
          <Script
            id="gtm-init"
            dangerouslySetInnerHTML={{
              __html: `window.gtm=window.gtm||[];window.gtm.push({event:'gtm.js','gtm.start':new Date().getTime()});`,
            }}
          />
        </>
      ) : null}

      {/* <Script
        id="unique identifier for the script, mandatory if using the raw code technique"
        src="script_url"
        // OR
        dangerouslySetInnerHTML={{ __html: 'raw code' }}
      /> */}

      <AppWrapper ref={heightRef}>
        <PageWrapper
          ref={pageRef}
          data-snap={!!pageConfig.scroll.snap}
          data-hidescrollbar={!!pageConfig.scroll.hideScrollbar}
          data-nonscrollable={!!pageConfig.scroll.disabled}
        >
          <Component {...pageProps} />

          <div id="bottom-root">
            <NotificationsHandler
              bgColor="#ffffff"
              colorConfig={{
                success: {
                  textColor: '#000000',
                  bgColor: '#d3eca7',
                  icon: { type: 'COMPONENT', data: CheckmarkIcon, props: { mainColor: '#ff00ff' } },
                },
                info: {
                  textColor: '#000000',
                  bgColor: '#cddeff',
                  icon: { type: 'COMPONENT', data: InfoIcon, props: { mainColor: '#ff00ff' } },
                },
                error: {
                  textColor: '#000000',
                  bgColor: '#ff5959',
                  icon: { type: 'COMPONENT', data: WarningIcon, props: { mainColor: '#ff00ff' } },
                },
              }}
            />
          </div>
        </PageWrapper>
      </AppWrapper>
    </>
  );
}
