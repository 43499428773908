/* eslint-disable max-classes-per-file */

/**
 * /!\ UGLY TRICK, BUT REQUIRED SINCE CustomEvent DOES NOT EXIST IN THE SERVER-SIDE CONTEXT.
 */
if (typeof global.CustomEvent !== 'function') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  global.CustomEvent = class FakeCustomEvent {};
}

/**
 * ScrollToTop
 */
export type ScrollToTopData = null;

export class ScrollToTopEvent extends CustomEvent<ScrollToTopData> {
  static eventName = 'once:scroll-to-top';

  constructor() {
    super(ScrollToTopEvent.eventName, {
      detail: null,
      cancelable: false,
    });
  }
}

/**
 * SnapScrollInfo
 */
export type SnapScrollInfoData = {
  position: number;
};

export class SnapScrollInfoEvent extends CustomEvent<SnapScrollInfoData> {
  static eventName = (snapScrollID: string): string => `once:${snapScrollID}:snap-scroll-info`;

  constructor(snapScrollID: string, data: SnapScrollInfoData) {
    super(SnapScrollInfoEvent.eventName(snapScrollID), {
      detail: data,
      cancelable: false,
    });
  }
}

/**
 * SnapScrollToSection
 */
export type SnapScrollToSectionData = {
  index: number;
};

export class SnapScrollToSectionEvent extends CustomEvent<SnapScrollToSectionData> {
  static eventName = (snapScrollID: string): string => `once:${snapScrollID}:snap-scroll-to-section`;

  constructor(snapScrollID: string, data: SnapScrollToSectionData) {
    super(SnapScrollToSectionEvent.eventName(snapScrollID), {
      detail: data,
      cancelable: false,
    });
  }
}

/**
 * ScrollInfo
 */

export type ScrollInfoData = {
  index: number;
};

export class ScrollInfoEvent extends CustomEvent<ScrollInfoData> {
  static eventName = (scrollID: string): string => `once:${scrollID}:scroll-info`;

  constructor(scrollID: string, data: ScrollInfoData) {
    super(ScrollInfoEvent.eventName(scrollID), {
      detail: data,
      cancelable: false,
    });
  }
}

/**
 * ScrollToChild
 */

export type ScrollToChildData = {
  index: number;
};

export class ScrollToChildEvent extends CustomEvent<ScrollToChildData> {
  static eventName = (scrollID: string): string => `once:${scrollID}:scroll-child`;

  constructor(scrollID: string, data: ScrollToChildData) {
    super(ScrollToChildEvent.eventName(scrollID), {
      detail: data,
      cancelable: false,
    });
  }
}
