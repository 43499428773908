import gql from 'graphql-tag';

import { FullPage } from '../fragments';

export const PAGE_BY_HANDLE_QUERY = gql`
  query pageByHandle($handle: String!) {
    page(handle: $handle) {
      ...FullPage
    }
  }
  ${FullPage}
`;

export const PAGE_BY_ID_QUERY = gql`
  query pageByID($id: ID!) {
    page(id: $id) {
      ...FullPage
    }
  }
  ${FullPage}
`;

export const PAGES_QUERY = gql`
  query pages($first: Int!) {
    pages(first: $first) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          ...FullPage
        }
      }
    }
  }
  ${FullPage}
`;
