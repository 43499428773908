import { CommonProps } from '@once/types';

export function VolumeOffIcon({ mainColor, ...svgProps }: CommonProps.Icon.SimpleColor): JSX.Element {
  return (
    <svg {...svgProps} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.93 2.34 2.34.93l16.73 16.72-1.41 1.41-2.05-2.05A8.94 8.94 0 0 1 12 18.76V16.7c.8-.23 1.53-.62 2.18-1.11L10 11.41V18l-5-5H1V7h4l.29-.3L.93 2.34Zm15.66 10c.26-.73.41-1.52.41-2.34 0-3.17-2.11-5.85-5-6.71V1.23c4.01.91 7 4.49 7 8.77 0 1.39-.32 2.7-.88 3.87l-1.53-1.53ZM10 2 8.12 3.88 10 5.76V2Zm2 3.97A4.5 4.5 0 0 1 14.5 10c0 .08-.01.16-.02.24L12 7.76V5.97Z"
        fill={mainColor}
      />
    </svg>
  );
}
