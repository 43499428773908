// @action -> use resize observer

import { atom, useSetAtom } from 'jotai';
import { useEffect } from 'react';

export const WindowHeightAtom = atom<number>(0);
export const WindowWidthAtom = atom<number>(0);

export function useWindowSize(): void {
  const setWindowHeight = useSetAtom(WindowHeightAtom);
  const setWindowWidth = useSetAtom(WindowWidthAtom);

  useEffect(() => {
    setWindowHeight(window.innerHeight);
    setWindowWidth(window.innerWidth);
  }, [setWindowHeight, setWindowWidth]);
}
