import gql from 'graphql-tag';

import { FullArticle, FullBlog } from '../fragments';

export const BLOG_BY_ID_QUERY = gql`
  query blogByID($id: ID!) {
    blog(id: $id) {
      ...FullBlog
    }
  }
  ${FullBlog}
`;

export const BLOG_BY_SLUG_QUERY = gql`
  query blogBySlug($slug: String!) {
    blog(handle: $slug) {
      ...FullBlog
    }
  }
  ${FullBlog}
`;

export const BLOG_ARTICLES_BY_QUERY_AND_ID_QUERY = gql`
  query blogArticlesByQueryAndID($id: ID!, $query: String!, $first: Int!) {
    blog(id: $id) {
      id
      handle
      articlesByQuery: articles(first: $first, query: $query) {
        edges {
          node {
            ...FullArticle
          }
        }
      }
    }
  }
  ${FullArticle}
`;

export const BLOG_ARTICLES_BY_QUERY_AND_SLUG_QUERY = gql`
  query blogArticlesByQueryAndSlug($slug: String!, $query: String!, $first: Int!) {
    blog(handle: $slug) {
      id
      handle
      articlesByQuery: articles(first: $first, query: $query) {
        edges {
          node {
            ...FullArticle
          }
        }
      }
    }
  }
  ${FullArticle}
`;

export const BLOG_ARTICLE_BY_SLUG_AND_ID_QUERY = gql`
  query blogArticlesBySlugAndID($id: ID!, $slug: String!) {
    blog(id: $id) {
      id
      handle
      articleByHandle(handle: $slug) {
        ...FullArticle
      }
    }
  }
  ${FullArticle}
`;

export const BLOG_ARTICLE_BY_SLUGS_QUERY = gql`
  query blogArticlesBySlugs($slugBlog: String!, $slugArticle: String!) {
    blog(handle: $slugBlog) {
      id
      handle
      articleByHandle(handle: $slugArticle) {
        ...FullArticle
      }
    }
  }
  ${FullArticle}
`;

export const BLOG_ARTICLES_SLUG_BY_BLOG_SLUG = gql`
  query blogArticlesSlugByBlogSlug($slugBlog: String!) {
    blog(handle: $slugBlog) {
      articles(first: 250) {
        edges {
          node {
            id
            handle
          }
        }
      }
    }
  }
`;

export const BLOG_ARTICLE_SLUGS_BY_FIRST_BLOG = gql`
  query blogArticleSlugsByFirstBlog($maxBlogArticles: Int!) {
    blogs(first: 1) {
      nodes {
        articles(first: $maxBlogArticles) {
          nodes {
            id
            handle
          }
        }
      }
    }
  }
`;
