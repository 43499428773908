import type { CommonProps } from '@once/types';

export function ArrowIcon({ mainColor, ...svgProps }: CommonProps.Icon.SimpleColor): JSX.Element {
  return (
    <svg {...svgProps} viewBox="0 0 11 18">
      <path
        d="M0.804539 15.5864C0.3602 16.0307 0.360201 16.7511 0.804539 17.1955C1.24888 17.6398 1.96929 17.6398 2.41363 17.1955L9.7909 9.81818C10.2428 9.36631 10.2428 8.63369 9.7909 8.18182L2.41363 0.804544C1.96929 0.360206 1.24888 0.360206 0.80454 0.804545C0.360202 1.24888 0.360202 1.9693 0.80454 2.41364L7.3909 9L0.804539 15.5864Z"
        fill={mainColor}
      />
    </svg>
  );
}
