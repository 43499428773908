import gql from 'graphql-tag';

import { FullProduct, FullImage } from '../fragments';

export const PRODUCT_BY_HANDLE_QUERY = gql`
  query productByHandle($handle: String!) {
    product(handle: $handle) {
      ...FullProduct
    }
  }
  ${FullProduct}
`;

export const PRODUCT_BY_ID_QUERY = gql`
  query productByID($id: ID!) {
    product(id: $id) {
      ...FullProduct
    }
  }
  ${FullProduct}
`;

export const PRODUCT_IMAGES_BY_HANDLE_QUERY = gql`
  query productImagesByHandle($handle: String!) {
    product(handle: $handle) {
      images(first: 250) {
        edges {
          node {
            ...FullImage
          }
        }
      }
    }
  }
  ${FullImage}
`;

export const PRODUCT_IMAGES_BY_ID_QUERY = gql`
  query productImagesByID($id: ID!) {
    product(id: $id) {
      images(first: 250) {
        edges {
          node {
            ...FullImage
          }
        }
      }
    }
  }
  ${FullImage}
`;

export const PRODUCT_ID_BY_HANDLE_QUERY = gql`
  query productIDByHandle($handle: String!) {
    product(handle: $handle) {
      id
    }
  }
`;

export const PRODUCTS_QUERY = gql`
  query products($first: Int!) {
    products(first: $first) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          ...FullProduct
        }
      }
    }
  }
  ${FullProduct}
`;

export const PRODUCTS_HANDLES_QUERY = gql`
  query productsHandles($first: Int!) {
    products(first: $first) {
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          handle
        }
      }
    }
  }
`;

export const PRODUCTS_HANDLES_RELATED_QUERY = gql`
  query productsHandlesRelated($handle: String!) {
    product(handle: $handle) {
      id
      handle
      collections(first: 3) {
        pageInfo {
          hasPreviousPage
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            handle
            products(first: 250) {
              pageInfo {
                hasPreviousPage
                hasNextPage
              }
              edges {
                cursor
                node {
                  id
                  handle
                }
              }
            }
          }
        }
      }
    }
  }
`;
