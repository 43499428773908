import styled, { keyframes } from 'styled-components';

export const NotificationsWrapper = styled.div`
  position: fixed;
  top: 8px;
  right: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  z-index: 200;
  width: calc(100% - 16px);

  @media screen and (min-width: 768px) {
    max-width: 60vw;
  }
`;

type NotificationContainerProps = {
  'data-fading': boolean;
  $textColor: string;
  $bgColor: string;
};

export const NotificationContainer = styled.div<NotificationContainerProps>`
  position: relative;
  padding: 12px 16px;
  color: #ffffff;
  background-color: ${(p) => p.$bgColor};
  width: 100%;
  border-radius: 12px;
  padding: 20px 16px;
  overflow: hidden;
  text-align: center;
  opacity: 1;
  transition: all 0.8s ease-out;
  font-size: 0.8rem;
  text-align: left;

  &[data-fading='true'] {
    opacity: 0;
  }
`;

export const NotificationBadge = styled.span<{ $bgColor: string }>`
  height: 36px;
  width: 36px;
  min-height: 36px;
  min-width: 36px;
  border-radius: 50%;
  background: ${(p) => p.$bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const timerAnimation = keyframes`
  0% { width: 0% }
  100% { width: 100% }
`;

type TimerProps = {
  $duration: number;
  $color: string;
};

export const TimerContainer = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: var(--text-secondary-color);
`;

export const Timer = styled.div<TimerProps>`
  position: absolute;
  top: 0;
  left: 0;
  animation: ${timerAnimation} ${(p) => p.$duration + 400}ms cubic-bezier(0.1, 0.7, 0.15, 1);
  height: 4px;
  background-color: ${(p) => p.$color};
`;
