import gql from 'graphql-tag';

import { BigCommerceFullCollection, BigCommerceFullProduct } from '../fragments';

export const BIG_COMMERCE_COLLECTION_BY_ID_QUERY = gql`
  query bigCommerceCollectionByID($id: ID!, $maxProductsPerCollection: Int!) {
    node(id: $id) {
      __typename
      ... on Category {
        ...BigCommerceFullCollection
        products(first: $maxProductsPerCollection) {
          edges {
            __typename
            node {
              ...BigCommerceFullProduct
            }
          }
        }
      }
    }
  }
  ${BigCommerceFullCollection}
  ${BigCommerceFullProduct}
`;

export const BIG_COMMERCE_COLLECTION_BY_PATH_QUERY = gql`
  query bigCommerceCollectionByPath($path: String!, $maxProductsPerCollection: Int!) {
    site {
      route(path: $path) {
        node {
          __typename
          ... on Category {
            ...BigCommerceFullCollection
            products(first: $maxProductsPerCollection) {
              edges {
                __typename
                node {
                  ...BigCommerceFullProduct
                }
              }
            }
          }
        }
      }
    }
  }
  ${BigCommerceFullCollection}
  ${BigCommerceFullProduct}
`;

export const BIG_COMMERCE_COLLECTIONS_PATHS_QUERY = gql`
  query bigCommerceCollectionsPaths {
    site {
      categoryTree {
        entityId
        path
        name
      }
    }
  }
`;
