import gql from 'graphql-tag';

import { FullCart } from '../fragments';

export const CART_BY_ID_QUERY = gql`
  query cartByID($id: ID!) {
    cart(id: $id) {
      ...FullCart
    }
  }
  ${FullCart}
`;
