import { atom, useSetAtom, useAtomValue } from 'jotai';
import { useEffect } from 'react';

type DeviceType = 'mobile' | 'tablet' | 'desktop';

export const MQLAtom = atom<DeviceType>('desktop');

// This is a quick sketched version
export function useMQL(): void {
  const setDeviceType = useSetAtom(MQLAtom);

  useEffect(() => {
    const tabletMQL = window.matchMedia('(min-width : 768px) and (max-width : 1024px)');
    const mobileMQL = window.matchMedia('(min-width : 320px) and (max-width : 480px)');
    const desktopMQL = window.matchMedia('(min-width: 1024px)');

    if (mobileMQL.matches) {
      setDeviceType('mobile');
    } else if (tabletMQL.matches) {
      setDeviceType('tablet');
    } else if (desktopMQL.matches) {
      setDeviceType('desktop');
    }

    function handleMobile(event: MediaQueryListEvent): void {
      if (event.matches) {
        setDeviceType('mobile');
      }
    }
    function handleTablet(event: MediaQueryListEvent): void {
      if (event.matches) {
        setDeviceType('tablet');
      }
    }
    function handleDesktop(event: MediaQueryListEvent): void {
      if (event.matches) {
        setDeviceType('desktop');
      }
    }

    mobileMQL.addEventListener?.('change', handleMobile);
    tabletMQL.addEventListener?.('change', handleTablet);
    desktopMQL.addEventListener?.('change', handleDesktop);

    return () => {
      mobileMQL.removeEventListener?.('change', handleMobile);
      tabletMQL.removeEventListener?.('change', handleTablet);
      desktopMQL.removeEventListener?.('change', handleDesktop);
    };
  }, [setDeviceType]);
}

export function useIsResponsive(): boolean {
  const device = useAtomValue(MQLAtom);
  return device === 'tablet';
}
