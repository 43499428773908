/* eslint-disable max-classes-per-file */

/**
 * /!\ UGLY TRICK, BUT REQUIRED SINCE CustomEvent DOES NOT EXIST IN THE SERVER-SIDE CONTEXT.
 */
if (typeof global.CustomEvent !== 'function') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  global.CustomEvent = class FakeCustomEvent {};
}

/**
 * CustomerSignup
 */
export type CustomerSignupData = null;

export class CustomerSignupEvent extends CustomEvent<CustomerSignupData> {
  static eventName = 'once-customer:signup';

  constructor() {
    super(CustomerSignupEvent.eventName, {
      detail: null,
      cancelable: false,
    });
  }
}

/**
 * CustomerLogin
 */
export type CustomerLoginData = null;

export class CustomerLoginEvent extends CustomEvent<CustomerLoginData> {
  static eventName = 'once-customer:login';

  constructor() {
    super(CustomerLoginEvent.eventName, {
      detail: null,
      cancelable: false,
    });
  }
}
