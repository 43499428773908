import gql from 'graphql-tag';

export const BigCommerceFullImage = gql`
  fragment BigCommerceFullImage on Image {
    altText
    urlOriginal
    isDefault
    url320wide: url(width: 320)
    url640wide: url(width: 640)
    url960wide: url(width: 960)
    url1280wide: url(width: 1280)
  }
`;

export const BigCommerceFullMoney = gql`
  fragment BigCommerceFullMoney on Money {
    value
    currencyCode
  }
`;

export const BigCommerceFullMetafields = gql`
  fragment BigCommerceFullMetafields on Metafields {
    id
    entityId
    key
    value
  }
`;

export const BigCommerceFullOptions = gql`
  fragment BigCommerceFullOptions on CatalogProductOption {
    entityId
    displayName
    isRequired
    ... on MultipleChoiceOption {
      displayName
      isRequired
      values(first: 50) {
        edges {
          node {
            label
          }
        }
      }
    }
  }
`;

export const BigCommerceFullVariant = gql`
  fragment BigCommerceFullVariant on Variant {
    id
    sku
    entityId
    isPurchasable
    inventory {
      aggregated {
        availableToSell
      }
    }
    prices {
      price {
        ...BigCommerceFullMoney
      }
      basePrice {
        ...BigCommerceFullMoney
      }
      salePrice {
        ...BigCommerceFullMoney
      }
    }
    defaultImage {
      ...BigCommerceFullImage
    }

    options {
      edges {
        node {
          displayName
          values {
            edges {
              node {
                label
              }
            }
          }
        }
      }
    }
  }
  ${BigCommerceFullImage}
  ${BigCommerceFullMoney}
  ${BigCommerceFullOptions}
`;

export const BigCommerceBasicProduct = gql`
  fragment BigCommerceBasicProduct on Product {
    id
    name
    path
    type
    brand {
      name
    }
  }
`;

export const BigCommerceFullProduct = gql`
  fragment BigCommerceFullProduct on Product {
    id
    entityId
    path
    name
    type
    sku
    brand {
      name
    }
    variants(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...BigCommerceFullVariant
        }
      }
    }
    productOptions(first: 50) {
      edges {
        node {
          ...BigCommerceFullOptions
        }
      }
    }
    plainTextDescription
    description
    defaultImage {
      ...BigCommerceFullImage
    }
    images {
      edges {
        node {
          ...BigCommerceFullImage
        }
      }
    }
    reviewSummary {
      summationOfRatings
      numberOfReviews
    }
    prices {
      price {
        ...BigCommerceFullMoney
      }
      priceRange {
        min {
          ...BigCommerceFullMoney
        }
        max {
          ...BigCommerceFullMoney
        }
      }
      salePrice {
        ...BigCommerceFullMoney
      }
      retailPrice {
        ...BigCommerceFullMoney
      }
      saved {
        ...BigCommerceFullMoney
      }
    }
    brand {
      name
    }
  }
  ${BigCommerceFullVariant}
  ${BigCommerceFullImage}
  ${BigCommerceFullMoney}
  ${BigCommerceFullOptions}
`;

export const BigCommerceFullCollection = gql`
  fragment BigCommerceFullCollection on Category {
    id
    entityId
    path
    name
    description
    defaultImage {
      ...BigCommerceFullImage
    }
  }
  ${BigCommerceFullImage}
`;
