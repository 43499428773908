import gql from 'graphql-tag';

import { FullCart } from '../fragments';

export const CREATE_CART_MUTATION = gql`
  mutation createCart {
    cartCreate {
      cart {
        ...FullCart
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${FullCart}
`;

export const SET_CART_CUSTOM_DATA_MUTATION = gql`
  mutation setCartCustomData($cartID: ID!, $attributes: [AttributeInput!]!) {
    cartAttributesUpdate(cartId: $cartID, attributes: $attributes) {
      cart {
        ...FullCart
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${FullCart}
`;

export const ADD_TO_CART_MUTATION = gql`
  mutation addToCart($cartID: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartID, lines: $lines) {
      cart {
        ...FullCart
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${FullCart}
`;

export const REMOVE_FROM_CART_MUTATION = gql`
  mutation removeFromCart($cartID: ID!, $lineIDs: [ID!]!) {
    cartLinesRemove(cartId: $cartID, lineIds: $lineIDs) {
      cart {
        ...FullCart
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${FullCart}
`;

export const UPDATE_ITEMS_IN_CART_MUTATION = gql`
  mutation updateItemsInCart($cartID: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartID, lines: $lines) {
      cart {
        ...FullCart
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${FullCart}
`;

export const UPDATE_CART_NOTE_MUTATION = gql`
  mutation updateCartNote($cartID: ID!, $note: String) {
    cartNoteUpdate(cartId: $cartID, note: $note) {
      cart {
        ...FullCart
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${FullCart}
`;

export const UPDATE_CART_DISCOUNTS_MUTATION = gql`
  mutation updateCartDiscounts($cartID: ID!, $codes: [String!]) {
    cartDiscountCodesUpdate(cartId: $cartID, discountCodes: $codes) {
      cart {
        ...FullCart
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${FullCart}
`;

export const UPDATE_CART_ATTRIBUTES_MUTATION = gql`
  mutation cartAttributesUpdate($cartID: ID!, $attributes: [AttributeInput!]!) {
    cartAttributesUpdate(cartId: $cartID, attributes: $attributes) {
      cart {
        ...FullCart
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${FullCart}
`;
