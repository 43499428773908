/* eslint-disable max-classes-per-file */

/**
 * /!\ UGLY TRICK, BUT REQUIRED SINCE CustomEvent DOES NOT EXIST IN THE SERVER-SIDE CONTEXT.
 */
if (typeof global.CustomEvent !== 'function') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  global.CustomEvent = class FakeCustomEvent {};
}

/**
 * Notification
 */
export type NotificationData = {
  id?: string;
  data: {
    title: string;
    description?: string;
  };
  delay?: number;
  type: 'success' | 'info' | 'error';
};

export class NotificationEvent extends CustomEvent<NotificationData> {
  static eventName = 'once-notification:new';

  constructor(data: NotificationData) {
    super(NotificationEvent.eventName, {
      detail: data,
      cancelable: true,
    });
  }
}

/**
 * ClearNotification
 */
export type ClearNotificationData = {
  id: string;
};

export class ClearNotificationEvent extends CustomEvent<ClearNotificationData> {
  static eventName = 'once-notification:clear';

  constructor(data: ClearNotificationData) {
    super(ClearNotificationEvent.eventName, {
      detail: data,
      cancelable: true,
    });
  }
}
