/* eslint-disable max-classes-per-file */

/**
 * /!\ UGLY TRICK, BUT REQUIRED SINCE CustomEvent DOES NOT EXIST IN THE SERVER-SIDE CONTEXT.
 */
if (typeof global.CustomEvent !== 'function') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  global.CustomEvent = class FakeCustomEvent {};
}

/**
 * CarouselGoTo
 */
export type CarouselGoToData = {
  position?: number;
  url?: string;
};

export class CarouselGoToEvent extends CustomEvent<CarouselGoToData> {
  static eventName = (carouselID: string): string => `once-carousel:${carouselID}:go-to`;

  constructor(carouselID: string, data?: CarouselGoToData) {
    super(CarouselGoToEvent.eventName(carouselID), {
      detail: data,
      cancelable: true,
    });
  }
}

/**
 * CarouselGoToNext
 */
export type CarouselGoToNextData = null;

export class CarouselGoToNextEvent extends CustomEvent<CarouselGoToNextData> {
  static eventName = (carouselID: string): string => `once-carousel:${carouselID}:go-to-next`;

  constructor(carouselID: string) {
    super(CarouselGoToNextEvent.eventName(carouselID), {
      detail: null,
      cancelable: true,
    });
  }
}

/**
 * CarouselGoToPrevious
 */
export type CarouselGoToPreviousData = null;

export class CarouselGoToPreviousEvent extends CustomEvent<CarouselGoToPreviousData> {
  static eventName = (carouselID: string): string => `once-carousel:${carouselID}:go-to-previous`;

  constructor(carouselID: string) {
    super(CarouselGoToPreviousEvent.eventName(carouselID), {
      detail: null,
      cancelable: true,
    });
  }
}

/**
 * CarouselCurrentPosition
 */
export type CarouselCurrentPositionData = {
  position: number;
  contentLength?: number;
};

export class CarouselCurrentPositionEvent extends CustomEvent<CarouselCurrentPositionData> {
  static eventName = (carouselID: string): string => `once-carousel:${carouselID}:current-position`;

  constructor(carouselID: string, data: CarouselCurrentPositionData) {
    super(CarouselCurrentPositionEvent.eventName(carouselID), {
      detail: data,
      cancelable: true,
    });
  }
}
