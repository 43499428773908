import { atom, useSetAtom } from 'jotai';
import { RefObject, useEffect, useRef } from 'react';

// import { isMobile } from '@once/utils/isMobile';

export const MobileDeltaAtom = atom(0);

export function useInitMobileDelta(fullHeightRef: RefObject<HTMLDivElement>): void {
  const setMobileDelta = useSetAtom(MobileDeltaAtom);

  const timeoutStamp = useRef<NodeJS.Timeout | undefined>();
  useEffect(() => {
    function getMobileDelta(): void {
      if (fullHeightRef.current) {
        if (typeof window !== undefined && window.navigator) {
          const delta =
            /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
              navigator.userAgent,
            )
              ? fullHeightRef.current.offsetHeight - window.innerHeight
              : 0;
          document.documentElement.style.setProperty('--mobile-delta', `${delta}px`);
          setMobileDelta(delta);
        }
      }
    }

    function handleResize(): void {
      if (timeoutStamp.current) {
        clearTimeout(timeoutStamp.current);
      }
      // Since 2017 Mobile Safari resize event is posted before page layout
      // https://openradar.appspot.com/radar?id=5040881597939712
      // Apple themselves use this value in their code
      timeoutStamp.current = setTimeout(getMobileDelta, 500);
    }

    getMobileDelta();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [fullHeightRef, setMobileDelta]);
}
