/* eslint-disable max-classes-per-file */

/**
 * /!\ UGLY TRICK, BUT REQUIRED SINCE CustomEvent DOES NOT EXIST IN THE SERVER-SIDE CONTEXT.
 */
if (typeof global.CustomEvent !== 'function') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  global.CustomEvent = class FakeCustomEvent {};
}

/**
 * ModalOpen
 */
export type ModalOpenData = null;

export class ModalOpenEvent extends CustomEvent<ModalOpenData> {
  static eventName = (modalID: string): string => `once-modal:${modalID}:open`;

  constructor(modalID: string) {
    super(ModalOpenEvent.eventName(modalID), {
      detail: null,
      cancelable: true,
    });
  }
}

/**
 * ModalClose
 */
export type ModalCloseData = null;

export class ModalCloseEvent extends CustomEvent<ModalCloseData> {
  static eventName = (modalID: string): string => `once-modal:${modalID}:close`;

  constructor(modalID: string) {
    super(ModalCloseEvent.eventName(modalID), {
      detail: null,
      cancelable: true,
    });
  }
}

export type ToggleModalData = {
  open?: boolean;
};

export class ToggleModalEvent extends CustomEvent<ToggleModalData> {
  static eventName = (modalID: string): string => `once-modal:${modalID}:toggle`;

  constructor(modalID: string, data: ToggleModalData) {
    super(ToggleModalEvent.eventName(modalID), {
      detail: data,
      cancelable: true,
    });
  }
}
