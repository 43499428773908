import { CommonProps } from '@once/types';

export function InfoIcon({ mainColor, ...svgProps }: CommonProps.Icon.SimpleColor): JSX.Element {
  return (
    <svg {...svgProps} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm-1 15v-6h2v6h-2Zm0-10v2h2V7h-2Z"
        fill={mainColor}
      />
    </svg>
  );
}
