import { atom, Atom, PrimitiveAtom, useAtomValue, Setter } from 'jotai';
import { atomFamily, atomWithStorage, selectAtom, useAtomCallback } from 'jotai/utils';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useGetCommerceProvider } from '@once/data/client/useGetCommerceProvider';
import { AddToCartEvent, RemoveFromCartEvent } from '@once/events';
import { Storefront } from '@once/types';

import type { BigCommerceUpdateCartItem, BigCommerceFullCart } from '../common/bigCommerce/types';
import {
  CREATE_CART_MUTATION,
  SET_CART_CUSTOM_DATA_MUTATION,
  ADD_TO_CART_MUTATION,
  REMOVE_FROM_CART_MUTATION,
  UPDATE_ITEMS_IN_CART_MUTATION,
} from '../common/shopify/mutations';
import { CART_BY_ID_QUERY } from '../common/shopify/queries';
import {
  FullCartFragment,
  CreateCartMutation,
  CreateCartMutationVariables,
  SetCartCustomDataMutation,
  SetCartCustomDataMutationVariables,
  AddToCartMutation,
  AddToCartMutationVariables,
  RemoveFromCartMutation,
  RemoveFromCartMutationVariables,
  UpdateItemsInCartMutation,
  UpdateItemsInCartMutationVariables,
  CartByIdQuery,
  CartByIdQueryVariables,
} from '../common/shopify/schema';
import { shopifyFetch } from './fetch';

export type CartItemInput = {
  id: string;
  quantity: number;
  customData?: Record<string, unknown>;
  productID?: string;
  variantID?: string;
};

const DEFAULT_LOCALE = '';

const safeJSONParse = (input?: string | null): any => {
  if (!input) {
    return input;
  }

  try {
    const ret = JSON.parse(input);
    return ret;
  } catch (error) {
    return input;
  }
};

const CartIDAtom = atomFamily<string, PrimitiveAtom<string | null>>((locale) =>
  atomWithStorage<string | null>(`cartID_${locale}`, null),
);

const CartAtom = atomFamily<string, PrimitiveAtom<Storefront.Cart | null>>(() => atom<Storefront.Cart | null>(null));

async function bigCommerceCartFetcher(query: string, variables: Record<string, unknown>): Promise<any> {
  const ret = await fetch('/api/big-commerce', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  const res = await ret.json();

  if (!res || res.status) {
    return null;
  }

  return res;
}

const setBigCommerceCart = (set: Setter, locale: string, cart?: BigCommerceFullCart | null): void => {
  if (cart) {
    const { code: currencyCode } = cart.currency;
    const physicals = cart.line_items.physical_items.map((phy) => ({ type: 'physical', ...phy }));
    const digitals = cart.line_items.digital_items.map((dig) => ({ type: 'digital', ...dig }));
    set(CartIDAtom(locale), cart.id);
    set(CartAtom(locale), {
      id: cart.id,
      note: null,
      estimatedCost: {
        subtotal: { amount: String(cart.base_amount), currencyCode },
        total: { amount: String(cart.cart_amount), currencyCode },
        totalDuty: null,
        totalTax: null,
      },
      checkoutURL: '',
      discounts: cart?.discounts?.map((d) => ({ applicable: true, code: d.id })) ?? [],
      items: [...physicals, ...digitals]
        .filter((e) => !!e)
        .map((p) => ({
          id: p.id,
          estimatedCost: {
            subtotal: { amount: String(p.list_price), currencyCode },
            total: { amount: String(p.list_price), currencyCode },
          },
          name: p.name,
          sku: p.sku,
          merchandise: {
            id: p.id,
            image: { id: p.id, blurDataURL: null, altText: p.name, height: null, width: null, url: p.image_url },
            name: p.name,
            sku: p.sku,
            options: p.options,
            availableForSale: p.quantity > 0,
            quantityAvailable: p.quantity,
            product: {
              id: `${p.product_id}`,
              name: p.name,
              slug: p.url,
              type: p.type,
              vendor: p.brand,
            },
            customFields: {},
            compareAtPrice: { amount: `${p.list_price}`, currencyCode },
            price: { amount: `${p.sale_price}`, currencyCode },
          },
          quantity: p.quantity,
          discountedAmount: p.discounts.map((d) => ({ amount: String(d.discountedAmount), currencyCode })),
          customData: {},
        })),
      customData: {},
    });
  } else {
    set(CartIDAtom(locale), null);
    set(CartAtom(locale), null);
  }
};

const setShopifyCart = (set: Setter, locale: string, cart?: FullCartFragment | null): void => {
  if (cart) {
    set(CartIDAtom(locale), cart.id);
    set(CartAtom(locale), {
      id: cart.id,
      note: cart.note ?? null,
      estimatedCost: {
        subtotal: cart.estimatedCost.subtotalAmount,
        total: cart.estimatedCost.totalAmount,
        totalDuty: cart.estimatedCost.totalDutyAmount ?? null,
        totalTax: cart.estimatedCost.totalTaxAmount ?? null,
      },
      checkoutURL: cart.checkoutUrl,
      discounts: cart.discountCodes,
      items: cart.lines.edges.map(({ node: line }) => ({
        id: line.id,
        estimatedCost: {
          subtotal: line.estimatedCost.subtotalAmount,
          total: line.estimatedCost.subtotalAmount,
        },
        name:
          line.merchandise.title !== 'Default Title'
            ? `${line.merchandise.product.title} - ${line.merchandise.title}`
            : line.merchandise.product.title,
        merchandise: {
          id: line.merchandise.id,
          name: line.merchandise.title,
          options: line.merchandise.selectedOptions,
          availableForSale: !!line.merchandise.availableForSale,
          product: {
            id: line.merchandise.product.id,
            name: line.merchandise.product.title,
            slug: line.merchandise.product.handle,
            type: line.merchandise.product.productType,
            vendor: line.merchandise.product.vendor,
          },
          sku: line.merchandise.sku ?? null,
          price: line.merchandise.priceV2,
          compareAtPrice: line.merchandise.compareAtPriceV2 ?? null,
          quantityAvailable: line.merchandise.quantityAvailable ?? null,
          image: line.merchandise.image
            ? {
                id: line.merchandise.image.id ?? null,
                url: line.merchandise.image.originalSrc,
                altText: line.merchandise.image.altText ?? null,
                width: line.merchandise.image.width ?? null,
                height: line.merchandise.image.height ?? null,
                blurDataURL: null,
              }
            : null,
          customFields: {},
        },
        quantity: line.quantity,
        discountedAmount: line.discountAllocations.map((d) => d.discountedAmount), // We can know here if it's either a `CartAutomaticDiscountAllocation` or `CartCodeDiscountAllocation` using __typename
        customData: line.attributes.reduce((acc, cur) => ({ ...acc, [cur.key]: safeJSONParse(cur.value) }), {}),
      })),
      customData: cart.attributes.reduce((acc, cur) => ({ ...acc, [cur.key]: safeJSONParse(cur.value) }), {}),
    });
  } else {
    set(CartIDAtom(locale), null);
    set(CartAtom(locale), null);
  }
};

const TotalCartItemsAtom = atomFamily<string, Atom<number>>((locale) =>
  selectAtom(CartAtom(locale), (cart) => cart?.items.length || 0),
);

let cartFetching = false;

export function useCart(): Storefront.Cart | null {
  const { locale = DEFAULT_LOCALE } = useRouter();
  const commerceProvider = useGetCommerceProvider();
  const cart = useAtomValue(CartAtom(locale));

  const fetchCart = useAtomCallback(async (get, set) => {
    const cartID = get(CartIDAtom(locale));

    if (cartID) {
      switch (commerceProvider?.type) {
        case 'SHOPIFY': {
          const res = await shopifyFetch<CartByIdQuery, CartByIdQueryVariables>(
            CART_BY_ID_QUERY,
            {
              id: cartID,
            },
            commerceProvider.fetchConfig,
          );
          setShopifyCart(set, locale, res?.cart);
          break;
        }
        case 'BIG_COMMERCE': {
          const res = await bigCommerceCartFetcher('get_cart', { cartID });

          setBigCommerceCart(set, locale, res?.data);
          break;
        }
        default:
          throw new Error('Commerce data provider is not handled yet in "client/cart#useCart"');
      }
    }
  });
  useEffect(() => {
    // Try to fetch the cart on first render, only if it's null and only 1 time
    // (ie. if cart is already set, or currently fetching, do not try to fetch)
    if (!cart && !cartFetching && commerceProvider) {
      cartFetching = true;
      fetchCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commerceProvider]);

  return cart;
}

export function useSetCartCustomData(): (cartCustomData: Record<string, any>) => Promise<Storefront.Cart | null> {
  const { locale = DEFAULT_LOCALE } = useRouter();
  const commerceProvider = useGetCommerceProvider();

  return useAtomCallback(async (get, set, cartCustomData) => {
    let cartID = get(CartIDAtom(locale));

    switch (commerceProvider?.type) {
      case 'SHOPIFY': {
        if (!cartID) {
          const res = await shopifyFetch<CreateCartMutation, CreateCartMutationVariables>(
            CREATE_CART_MUTATION,
            {},
            commerceProvider.fetchConfig,
          );
          cartID = res?.cartCreate?.cart?.id ?? null;
        }

        if (!cartID) {
          throw new Error('Cart does not exist');
        }

        const res = await shopifyFetch<SetCartCustomDataMutation, SetCartCustomDataMutationVariables>(
          SET_CART_CUSTOM_DATA_MUTATION,
          {
            cartID,
            attributes: Object.entries(cartCustomData)
              .filter(([, value]) => value !== undefined)
              .map(([key, value]) => ({ key, value: JSON.stringify(value) })),
          },
          commerceProvider.fetchConfig,
        );
        setShopifyCart(set, locale, res?.cartAttributesUpdate?.cart);
        return get(CartAtom(locale));
      }

      case 'BIG_COMMERCE': {
        // Does nothing indeed
        return get(CartAtom(locale));
      }
      default:
        throw new Error('Commerce data provider is not handled yet in "client/cart#useSetCartCustomData"');
    }
  });
}

export function useAddToCart(): (items: Storefront.AddCartItemData[]) => Promise<Storefront.Cart | null> {
  const { locale = DEFAULT_LOCALE } = useRouter();
  const commerceProvider = useGetCommerceProvider();

  return useAtomCallback(async (get, set, items) => {
    document.dispatchEvent(new AddToCartEvent({ items }));

    let cartID = get(CartIDAtom(locale));
    switch (commerceProvider?.type) {
      case 'SHOPIFY': {
        if (!cartID) {
          const res = await shopifyFetch<CreateCartMutation, CreateCartMutationVariables>(
            CREATE_CART_MUTATION,
            {},
            commerceProvider.fetchConfig,
          );
          cartID = res?.cartCreate?.cart?.id ?? null;
        }

        if (!cartID) {
          throw new Error('Cart does not exist');
        }

        const res = await shopifyFetch<AddToCartMutation, AddToCartMutationVariables>(
          ADD_TO_CART_MUTATION,
          {
            cartID,
            lines: items.map((i) => ({
              merchandiseId: i.variant.id,
              quantity: i.quantity,
              attributes: i.customData
                ? Object.entries(i.customData)
                    .filter(([, value]) => value !== undefined)
                    .map(([key, value]) => ({ key, value: JSON.stringify(value) }))
                : undefined,
            })),
          },
          commerceProvider.fetchConfig,
        );
        setShopifyCart(set, locale, res?.cartLinesAdd?.cart);
        return get(CartAtom(locale));
      }

      case 'BIG_COMMERCE': {
        const lineItems = items.map(({ quantity, variant, customData }) => ({
          product_id: Number(variant.product.id),
          variant_id: Number(variant.id),
          quantity,
          list_price: customData?.listPrice,
        }));

        const res = await bigCommerceCartFetcher('add_item', { cartID, lineItems });

        setBigCommerceCart(set, locale, res?.data);

        return get(CartAtom(locale));
      }
      default:
        throw new Error('Commerce data provider is not handled yet in "client/cart#useAddToCart"');
    }
  });
}

export function useRemoveItemsFromCart(): (lineIDs: string[]) => Promise<Storefront.Cart | null> {
  const { locale = DEFAULT_LOCALE } = useRouter();
  const commerceProvider = useGetCommerceProvider(locale);

  return useAtomCallback(async (get, set, lineIDs) => {
    const cart = get(CartAtom(locale));
    if (cart) {
      document.dispatchEvent(
        new RemoveFromCartEvent({ items: cart.items.filter((cartItem) => lineIDs.includes(cartItem.id)) }),
      );
    }

    const cartID = get(CartIDAtom(locale));
    switch (commerceProvider?.type) {
      case 'SHOPIFY': {
        if (cartID) {
          const res = await shopifyFetch<RemoveFromCartMutation, RemoveFromCartMutationVariables>(
            REMOVE_FROM_CART_MUTATION,
            {
              cartID,
              lineIDs,
            },
            commerceProvider.fetchConfig,
          );
          setShopifyCart(set, locale, res?.cartLinesRemove?.cart);
        }
        return get(CartAtom(locale));
      }
      case 'BIG_COMMERCE': {
        if (cartID) {
          const cartResponses = await Promise.all(
            lineIDs.map((itemID) => bigCommerceCartFetcher('delete_item', { cartID, itemID })),
          );

          const res = cartResponses.reverse().find((d) => d && 'data' in d) ?? {};

          setBigCommerceCart(set, locale, res?.data);
        }
        const a = get(CartAtom(locale));

        return a;
      }
      default:
        throw new Error('Commerce data provider is not handled yet in "client/cart#useRemoveItemsFromCart"');
    }
  });
}

export function useUpdateItemInCart(): (input: CartItemInput[]) => Promise<Storefront.Cart | null> {
  const { locale = DEFAULT_LOCALE } = useRouter();
  const commerceProvider = useGetCommerceProvider(locale);

  return useAtomCallback(async (get, set, input) => {
    const cartID = get(CartIDAtom(locale));
    switch (commerceProvider?.type) {
      case 'SHOPIFY': {
        if (cartID) {
          const res = await shopifyFetch<UpdateItemsInCartMutation, UpdateItemsInCartMutationVariables>(
            UPDATE_ITEMS_IN_CART_MUTATION,
            {
              cartID,
              lines: input.map((i) => ({
                id: i.id as string,
                quantity: i.quantity,
                attributes:
                  typeof i.customData !== 'undefined'
                    ? Object.entries(i.customData).map(([key, value]) => ({ key, value: JSON.stringify(value) }))
                    : undefined,
              })),
            },
            commerceProvider.fetchConfig,
          );
          setShopifyCart(set, locale, res?.cartLinesUpdate?.cart);
        }
        return get(CartAtom(locale));
      }

      case 'BIG_COMMERCE': {
        if (!cartID) {
          return null;
        }
        const cartResponses = await Promise.all(
          input.map((i) => {
            const item: BigCommerceUpdateCartItem = {
              id: i.id,
              quantity: i.quantity,
              productId: i.productID,
              variantId: i.variantID,
              listPrice: typeof i.customData?.listPrice === 'number' ? i.customData?.listPrice : undefined,
            };
            return bigCommerceCartFetcher('update_cart', { cartID, item });
          }),
        );

        const res = cartResponses?.reverse()?.find((d) => d && 'data' in d) ?? {};

        setBigCommerceCart(set, locale, res?.data);
        return get(CartAtom(locale));
      }
      default:
        throw new Error('Commerce data provider is not handled yet in "client/cart#useUpdateItemInCart"');
    }
  });
}

export function useTotalCartItems(): number {
  const { locale = DEFAULT_LOCALE } = useRouter();

  return useAtomValue(TotalCartItemsAtom(locale));
}
