/* eslint-disable max-classes-per-file */
import type { SwipeDirections } from 'react-swipeable';

import type { Drawer } from '@once/types';

/**
 * /!\ UGLY TRICK, BUT REQUIRED SINCE CustomEvent DOES NOT EXIST IN THE SERVER-SIDE CONTEXT.
 */
if (typeof global.CustomEvent !== 'function') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  global.CustomEvent = class FakeCustomEvent {};
}

/**
 * DrawerSwipeStart
 */
export type DrawerSwipeStartData = {
  currentStep: Drawer.StepWithID | null;
};

export class DrawerSwipeStartEvent extends CustomEvent<DrawerSwipeStartData> {
  static eventName = (drawerID: string): string => `once-drawer:${drawerID}:swipe-start`;

  constructor(drawerID: string, data: DrawerSwipeStartData) {
    super(DrawerSwipeStartEvent.eventName(drawerID), {
      detail: data,
      cancelable: false,
    });
  }
}

/**
 * DrawerSwiping
 */
export type DrawerSwipingData = {
  swipeDirection: SwipeDirections;
  swipePosition: number;
  deltaY: number;
  initial: number;
  absY: number;
  currentStep: Drawer.StepWithID;
  nextStep: Drawer.StepWithID;
};

export class DrawerSwipingEvent extends CustomEvent<DrawerSwipingData> {
  static eventName = (drawerID: string): string => `once-drawer:${drawerID}:swiping`;

  constructor(drawerID: string, data: DrawerSwipingData) {
    super(DrawerSwipingEvent.eventName(drawerID), {
      detail: data,
      cancelable: true,
    });
  }
}

/**
 * DrawerSwipeUp
 */
export type DrawerSwipeUpData = {
  currentStep: Drawer.StepWithID | null;
  nextStep: Drawer.StepWithID;
};

export class DrawerSwipeUpEvent extends CustomEvent<DrawerSwipeUpData> {
  static eventName = (drawerID: string): string => `once-drawer:${drawerID}:swipe-up`;

  constructor(drawerID: string, data: DrawerSwipeUpData) {
    super(DrawerSwipeUpEvent.eventName(drawerID), {
      detail: data,
      cancelable: true,
    });
  }
}

/**
 * DrawerSwipeDown
 */
export type DrawerSwipeDownData = {
  currentStep: Drawer.StepWithID | null;
  nextStep: Drawer.StepWithID;
};

export class DrawerSwipeDownEvent extends CustomEvent<DrawerSwipeDownData> {
  static eventName = (drawerID: string): string => `once-drawer:${drawerID}:swipe-down`;

  constructor(drawerID: string, data: DrawerSwipeDownData) {
    super(DrawerSwipeDownEvent.eventName(drawerID), {
      detail: data,
      cancelable: true,
    });
  }
}

/**
 * DrawerSwipeEnd
 */
export type DrawerSwipeEndData = {
  currentStep: Drawer.StepWithID;
  nextStep: Drawer.StepWithID;
};

export class DrawerSwipeEndEvent extends CustomEvent<DrawerSwipeEndData> {
  static eventName = (drawerID: string): string => `once-drawer:${drawerID}:swipe-end`;

  constructor(drawerID: string, data: DrawerSwipeEndData) {
    super(DrawerSwipeEndEvent.eventName(drawerID), {
      detail: data,
      cancelable: true,
    });
  }
}

/**
 * DrawerStep
 */
export type DrawerStepData = Drawer.StepWithID;

export class DrawerStepEvent extends CustomEvent<DrawerStepData> {
  static eventName = (drawerID: string): string => `once-drawer:${drawerID}:current-step`;

  constructor(drawerID: string, data: DrawerStepData) {
    super(DrawerStepEvent.eventName(drawerID), {
      detail: data,
      cancelable: true,
    });
  }
}

/**
 * DrawerToggleOpen
 */
export type DrawerToggleOpenData = null;

export class DrawerToggleOpenEvent extends CustomEvent<DrawerToggleOpenData> {
  static eventName = (drawerID: string): string => `once-drawer:${drawerID}:toggle-open`;

  constructor(drawerID: string) {
    super(DrawerToggleOpenEvent.eventName(drawerID), {
      detail: null,
      cancelable: false,
    });
  }
}

/**
 * DrawerToggleMid
 */
export type DrawerToggleMidData = null;

export class DrawerToggleMidEvent extends CustomEvent<DrawerToggleMidData> {
  static eventName = (drawerID: string): string => `once-drawer:${drawerID}:toggle-mid`;

  constructor(drawerID: string) {
    super(DrawerToggleMidEvent.eventName(drawerID), {
      detail: null,
      cancelable: false,
    });
  }
}

/**
 * DrawerToggleClose
 */
export type DrawerToggleCloseData = null;

export class DrawerToggleCloseEvent extends CustomEvent<DrawerToggleCloseData> {
  static eventName = (drawerID: string): string => `once-drawer:${drawerID}:toggle-close`;

  constructor(drawerID: string) {
    super(DrawerToggleCloseEvent.eventName(drawerID), {
      detail: null,
      cancelable: false,
    });
  }
}
