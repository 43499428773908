import { CommonProps } from '@once/types';

export function VolumeOnIcon({ mainColor, ...svgProps }: CommonProps.Icon.SimpleColor): JSX.Element {
  return (
    <svg {...svgProps} viewBox="0 0 18 18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 2.29V.23c4.01.91 7 4.49 7 8.77 0 4.28-2.99 7.86-7 8.77v-2.06c2.89-.86 5-3.54 5-6.71s-2.11-5.85-5-6.71ZM0 6v6h4l5 5V1L4 6H0Zm13.5 3A4.5 4.5 0 0 0 11 4.97v8.05c1.48-.73 2.5-2.25 2.5-4.02Z"
        fill={mainColor}
      />
    </svg>
  );
}
