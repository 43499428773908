import gql from 'graphql-tag';

import { BigCommerceFullImage, BigCommerceFullProduct } from '../fragments';

export const BIG_COMMERCE_PRODUCT_BY_ID_QUERY = gql`
  query bigCommerceProductByID($id: ID!) {
    site {
      product(id: $id) {
        ...BigCommerceFullProduct
      }
    }
  }
  ${BigCommerceFullProduct}
`;

export const BIG_COMMERCE_PRODUCT_BY_HANDLE_QUERY = gql`
  query bigCommerceProductByHandle($handle: String!) {
    site {
      route(path: $handle) {
        node {
          __typename
          id
          ... on Product {
            ...BigCommerceFullProduct
          }
        }
      }
    }
  }
  ${BigCommerceFullProduct}
`;

export const BIG_COMMERCE_PRODUCT_IMAGES_BY_HANDLE_QUERY = gql`
  query bigCommerceProductImagesByHandle($handle: String!) {
    site {
      route(path: $handle) {
        node {
          ... on Product {
            images(first: 250) {
              edges {
                node {
                  ...BigCommerceFullImage
                }
              }
            }
          }
        }
      }
    }
  }
  ${BigCommerceFullImage}
`;

export const BIG_COMMERCE_PRODUCT_ID_BY_HANDLE_QUERY = gql`
  query bigCommerceProductIDByHandle($handle: String!) {
    site {
      route(path: $handle) {
        node {
          __typename
          ... on Product {
            id
          }
        }
      }
    }
  }
`;

export const BIG_COMMERCE_PRODUCTS_QUERY = gql`
  query bigCommerceProducts($first: Int!) {
    site {
      products(first: $first) {
        edges {
          node {
            ...BigCommerceFullProduct
          }
        }
      }
    }
  }
  ${BigCommerceFullProduct}
`;

export const BIG_COMMERCE_PRODUCTS_HANDLES_QUERY = gql`
  query bigCommerceProductHandles($first: Int!) {
    site {
      products(first: $first) {
        edges {
          node {
            id
            path
          }
        }
      }
    }
  }
`;
