import { RefObject } from 'react';
import styled, { css } from 'styled-components';

type FlexWrapperProps = {
  $alignItems?: string;
  $justifyContent?: string;
  $wrap?: string;
  $direction?: 'column' | 'row';
  $gap?: number;
  $borderRadius?: number;
  $grow?: number;
  $paddingTop?: number;
  $paddingRight?: number;
  $paddingBottom?: number;
  $paddingLeft?: number;
  $background?: string;
  $absolute?: boolean;
  $fillParent?: boolean;
  $overflowHidden?: boolean;
};

const fillParentCSS = css<FlexWrapperProps>`
  width: 100%;
  height: 100%;
`;

const absoluteCSS = css<FlexWrapperProps>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const FlexWrapper = styled.div<FlexWrapperProps>`
  display: flex;
  align-items: ${(p) => p.$alignItems};
  justify-content: ${(p) => p.$justifyContent};
  flex-wrap: ${(p) => p.$wrap};
  flex-direction: ${(p) => p.$direction ?? 'column'};
  gap: ${(p) => p.$gap}px;
  border-radius: ${(p) => p.$borderRadius ?? 0}px;
  flex-grow: ${(p) => p.$grow};
  padding: ${(p) => p.$paddingTop ?? 0}px ${(p) => p.$paddingRight ?? 0}px ${(p) => p.$paddingBottom ?? 0}px
    ${(p) => p.$paddingLeft ?? 0}px;
  position: relative;
  background: ${(p) => p.$background};
  overflow: ${(p) => (p.$overflowHidden ? 'hidden' : undefined)};

  ${(p) => (p.$absolute ? absoluteCSS : undefined)}
  ${(p) => (p.$fillParent ? fillParentCSS : undefined)}
`;

export type PaddingParams = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  horizontal?: number;
  vertical?: number;
};

export type FlexProps = {
  className?: string;
  alignItems?: string;
  justifyContent?: string;
  wrap?: string;
  direction?: 'column' | 'row';
  gap?: number;
  borderRadius?: number;
  grow?: number;
  padding?: number | PaddingParams;
  background?: string;
  absolute?: boolean;
  fillParent?: boolean;
  overflowHidden?: boolean;
  flexRef?: RefObject<HTMLDivElement>;
  children: React.ReactNode;
};

/**
 * This component is used by the site-generator to wrap components.
 * Prefer creating your own styled-components instead of using this one.
 */
export function Flex({
  className,
  alignItems,
  justifyContent,
  wrap,
  direction,
  gap,
  borderRadius,
  grow,
  padding,
  background,
  absolute,
  fillParent,
  overflowHidden,
  children,
  flexRef,
}: FlexProps): JSX.Element {
  const paddingTop = typeof padding === 'number' ? padding : padding?.top ?? padding?.vertical ?? 0;
  const paddingRight = typeof padding === 'number' ? padding : padding?.right ?? padding?.horizontal ?? 0;
  const paddingBottom = typeof padding === 'number' ? padding : padding?.bottom ?? padding?.vertical ?? 0;
  const paddingLeft = typeof padding === 'number' ? padding : padding?.left ?? padding?.horizontal ?? 0;

  return (
    <FlexWrapper
      ref={flexRef}
      className={className}
      $alignItems={alignItems}
      $justifyContent={justifyContent}
      $wrap={wrap}
      $direction={direction}
      $gap={gap}
      $borderRadius={borderRadius}
      $grow={grow}
      $paddingTop={paddingTop}
      $paddingRight={paddingRight}
      $paddingBottom={paddingBottom}
      $paddingLeft={paddingLeft}
      $background={background}
      $absolute={absolute}
      $fillParent={fillParent}
      $overflowHidden={overflowHidden}
    >
      {children}
    </FlexWrapper>
  );
}
