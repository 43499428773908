/* eslint-disable max-classes-per-file */

/**
 * /!\ UGLY TRICK, BUT REQUIRED SINCE CustomEvent DOES NOT EXIST IN THE SERVER-SIDE CONTEXT.
 */
if (typeof global.CustomEvent !== 'function') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  global.CustomEvent = class FakeCustomEvent {};
}

/**
 * MenuOpen
 */
type MenuOpenTabData = {
  tabID: string;
};

export class MenuOpenTabEvent extends CustomEvent<MenuOpenTabData> {
  static eventName = 'once-menu:open-tab-id';

  constructor(data: MenuOpenTabData) {
    super(MenuOpenTabEvent.eventName, {
      detail: data,
      cancelable: true,
    });
  }
}
