/* eslint-disable max-classes-per-file */

/**
 * /!\ UGLY TRICK, BUT REQUIRED SINCE CustomEvent DOES NOT EXIST IN THE SERVER-SIDE CONTEXT.
 */
if (typeof global.CustomEvent !== 'function') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  global.CustomEvent = class FakeCustomEvent {};
}

/**
 * AnchorCurrentSection
 */
export type AnchorCurrentSectionData = {
  currentSection: string;
};

export class AnchorCurrentSectionEvent extends CustomEvent<AnchorCurrentSectionData> {
  static eventName = 'once-anchor:current-section';

  constructor(data: AnchorCurrentSectionData) {
    super(AnchorCurrentSectionEvent.eventName, {
      detail: data,
      cancelable: true,
    });
  }
}

/**
 * AnchorToggleSection
 */
export type AnchorToggleSectionData = {
  toggleSection: string;
};

export class AnchorToggleSectionEvent extends CustomEvent<AnchorToggleSectionData> {
  static eventName = 'once-anchor:toggle-section';

  constructor(data: AnchorToggleSectionData) {
    super(AnchorToggleSectionEvent.eventName, {
      detail: data,
      cancelable: true,
    });
  }
}
