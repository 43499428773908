type GTMEvent = {
  event: string;
  [additionalData: string]: any;
};

export function gtmPushEvent(evt: GTMEvent): void {
  const gtm = typeof window !== 'undefined' ? (window as any).gtm : undefined;

  if (typeof gtm?.push === 'function') {
    gtm.push(evt);
  }
}
