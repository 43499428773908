/* eslint-disable max-classes-per-file */
/**
 * /!\ UGLY TRICK, BUT REQUIRED SINCE CustomEvent DOES NOT EXIST IN THE SERVER-SIDE CONTEXT.
 */
if (typeof global.CustomEvent !== 'function') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  global.CustomEvent = class FakeCustomEvent {};
}

/**
 * OnAnimatedNavBarClose
 */
export type OnAnimatedNavBarCloseData = null;

export class OnAnimatedNavBarCloseEvent extends CustomEvent<OnAnimatedNavBarCloseData> {
  static eventName = 'once-animated-navbar:on-close';

  constructor() {
    super(OnAnimatedNavBarCloseEvent.eventName, {
      detail: null,
      cancelable: false,
    });
  }
}
