/* eslint-disable max-classes-per-file */
import { Storefront } from '@once/types';

/**
 * /!\ UGLY TRICK, BUT REQUIRED SINCE CustomEvent DOES NOT EXIST IN THE SERVER-SIDE CONTEXT.
 */
if (typeof global.CustomEvent !== 'function') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  global.CustomEvent = class FakeCustomEvent {};
}

/**
 * CartToggleOpen
 */
export type CartToggleOpenData = {
  open?: boolean;
};

export class CartToggleOpenEvent extends CustomEvent<CartToggleOpenData> {
  static eventName = 'once-cart:cart-toggle-open';

  constructor(data: CartToggleOpenData) {
    super(CartToggleOpenEvent.eventName, {
      detail: data,
      cancelable: true,
    });
  }
}

/**
 * CartDrawerState
 */
export type CartDrawerStateData = {
  open: boolean;
};

export class CartDrawerStateEvent extends CustomEvent<CartDrawerStateData> {
  static eventName = 'once-cart:drawer-state';

  constructor(data: CartDrawerStateData) {
    super(CartDrawerStateEvent.eventName, {
      detail: data,
      cancelable: false,
    });
  }
}

/**
 * AddToCart
 */
type AddToCartData = {
  items: Storefront.AddCartItemData[];
};

export class AddToCartEvent extends CustomEvent<AddToCartData> {
  static eventName = 'once-cart:add-to-cart';

  constructor(data: AddToCartData) {
    super(AddToCartEvent.eventName, {
      detail: data,
      cancelable: true,
    });
  }
}

/**
 * RemoveFromCart
 */
type RemoveFromCartData = {
  items: Storefront.CartItem[];
};

export class RemoveFromCartEvent extends CustomEvent<RemoveFromCartData> {
  static eventName = 'once-cart:remove-from-cart';

  constructor(data: RemoveFromCartData) {
    super(RemoveFromCartEvent.eventName, {
      detail: data,
      cancelable: true,
    });
  }
}
