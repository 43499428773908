import { CommonProps } from '@once/types';

export function CheckmarkIcon({ mainColor, ...svgProps }: CommonProps.Icon.SimpleColor): JSX.Element {
  return (
    <svg {...svgProps} viewBox="0 0 14 14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.261 2.263a.61.61 0 0 1 .085.858l-6.735 8.21a.61.61 0 0 1-.866.078L1.73 8.843a.61.61 0 0 1 .79-.928l2.542 2.164 6.342-7.731a.61.61 0 0 1 .857-.085Z"
        fill={mainColor}
      />
    </svg>
  );
}
