/* eslint-disable max-classes-per-file */

/**
 * /!\ UGLY TRICK, BUT REQUIRED SINCE CustomEvent DOES NOT EXIST IN THE SERVER-SIDE CONTEXT.
 */
if (typeof global.CustomEvent !== 'function') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  global.CustomEvent = class FakeCustomEvent {};
}

/**
 * AccordionToggle
 */
export type AccordionToggleData = null;

export class AccordionToggleEvent extends CustomEvent<AccordionToggleData> {
  static eventName = (id: string): string => `once-accordion:${id}:opaque`;

  constructor(id: string) {
    super(AccordionToggleEvent.eventName(id), {
      detail: null,
      cancelable: false,
    });
  }
}
