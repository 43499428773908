// @action update logic / remove this
import { forwardRef } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

export type TextSize =
  | 'hero'
  | 'title'
  | 'subtitle_highlight'
  | 'subtitle'
  | 'base_highlight'
  | 'base'
  | 'small_highlight'
  | 'small'
  | 'surtitle_highlight'
  | 'surtitle'
  | 'caption'
  | 'tab_indicator_highlight'
  | 'tab_indicator';

// `Augmented Fourth` on https://type-scale.com
const fontSizeMapping: Record<TextSize, string> = {
  hero: '3rem',
  title: '1.999rem',
  subtitle_highlight: '1.414rem',
  subtitle: '1.414rem',
  base_highlight: '1rem',
  base: '1rem',
  small_highlight: '0.823rem',
  small: '0.823rem',
  surtitle_highlight: '0.823rem',
  surtitle: '0.823rem',
  caption: '0.707rem',
  tab_indicator_highlight: '0.5rem',
  tab_indicator: '0.5rem',
};

type FontWeight = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
const fontWeightMapping: Record<TextSize, FontWeight> = {
  hero: 500,
  title: 500,
  subtitle_highlight: 500,
  subtitle: 400,
  base_highlight: 500,
  base: 400,
  small_highlight: 500,
  small: 400,
  caption: 400,
  surtitle_highlight: 500,
  surtitle: 400,
  tab_indicator_highlight: 500,
  tab_indicator: 400,
};

const lineHeightMapping: Record<TextSize, number> = {
  hero: 1.1,
  title: 1.2,
  subtitle_highlight: 1.2,
  subtitle: 1.2,
  base_highlight: 1.4,
  base: 1.4,
  small_highlight: 1.5,
  small: 1.5,
  caption: 1.5,
  surtitle_highlight: 1.5,
  surtitle: 1.5,
  tab_indicator_highlight: 1.5,
  tab_indicator: 1.5,
};

const letterSpacingMapping: Record<TextSize, string | undefined> = {
  hero: undefined,
  title: undefined,
  subtitle_highlight: undefined,
  subtitle: undefined,
  base_highlight: undefined,
  base: undefined,
  small_highlight: undefined,
  small: undefined,
  caption: undefined,
  surtitle_highlight: '0.04em',
  surtitle: '0.04em',
  tab_indicator_highlight: undefined,
  tab_indicator: undefined,
};

const EllipsedCSS = (maxWidth: string): FlattenSimpleInterpolation => css`
  max-width: ${maxWidth};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MaxLinesCSS = (maxLines: number): FlattenSimpleInterpolation => css`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${maxLines};
  overflow: hidden;
`;

type StyledTextProps = {
  $size: TextSize;
  $color: string;
  $fontFamily?: string;
  $textDecoration?: string;
  $letterSpacing?: string;
  $bold?: boolean;
  $center?: boolean;
  $noWrap?: boolean;
  $ellipsed?: string;
  $maxLines?: number;
};

const StyledText = styled.p<StyledTextProps>`
  color: ${(p) => p.$color};
  font-family: ${(p) => p.$fontFamily ?? 'inherit'};
  font-size: ${(p) => fontSizeMapping[p.$size]};
  font-weight: ${(p) => (p.$bold ? 'bold' : fontWeightMapping[p.$size])};
  line-height: ${(p) => lineHeightMapping[p.$size]};
  letter-spacing: ${(p) => p.$letterSpacing ?? letterSpacingMapping[p.$size]};
  text-decoration: ${(p) => p.$textDecoration};
  margin: 0;
  text-align: ${(p) => (p.$center ? 'center' : undefined)};
  white-space: ${(p) => (p.$noWrap || p.$ellipsed ? 'nowrap' : 'normal')};
  ${(p) => (p.$ellipsed ? EllipsedCSS(p.$ellipsed) : null)}
  ${(p) => (typeof p.$maxLines === 'number' && p.$maxLines > 0 ? MaxLinesCSS(p.$maxLines) : null)}
`;

export type TextProps = {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span';
  size: TextSize;
  color?: string;
  fontFamily?: string;
  textDecoration?: string;
  letterSpacing?: string;
  bold?: boolean;
  center?: boolean;
  className?: string;
  children: React.ReactNode;
  noWrap?: boolean;
  ellipsed?: string;
  maxLines?: number;
};

const isGoodSize = (size: string): size is TextSize =>
  [
    'hero',
    'title',
    'subtitle_highlight',
    'subtitle',
    'base_highlight',
    'base',
    'small_highlight',
    'small',
    'surtitle_highlight',
    'surtitle',
    'caption',
    'tab_indicator_highlight',
    'tab_indicator',
  ].includes(size);

export const Text = forwardRef<HTMLParagraphElement, TextProps>(function Text(
  {
    as,
    size,
    color = 'var(--text-color)',
    fontFamily,
    textDecoration,
    letterSpacing,
    bold,
    center,
    className,
    children,
    noWrap,
    ellipsed,
    maxLines,
  },
  ref,
): JSX.Element {
  if (!isGoodSize(size)) {
    throw new Error(`Text: ${size} is not handled.`);
  }

  return (
    <StyledText
      as={as}
      ref={ref}
      className={className}
      $size={size}
      $color={color}
      $fontFamily={fontFamily}
      $letterSpacing={letterSpacing}
      $textDecoration={textDecoration}
      $bold={bold}
      $center={center}
      $noWrap={noWrap}
      $ellipsed={ellipsed}
      $maxLines={maxLines}
    >
      {children}
    </StyledText>
  );
});
