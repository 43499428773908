/* eslint-disable max-classes-per-file */

import { Storefront } from '@once/types';

/**
 * /!\ UGLY TRICK, BUT REQUIRED SINCE CustomEvent DOES NOT EXIST IN THE SERVER-SIDE CONTEXT.
 */
if (typeof global.CustomEvent !== 'function') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  global.CustomEvent = class FakeCustomEvent {};
}

/**
 * ProductClick
 */
export type ProductClickData = {
  product: Storefront.Product;
};

export class ProductClickEvent extends CustomEvent<ProductClickData> {
  static eventName = 'once-product:click';

  constructor(data: ProductClickData) {
    super(ProductClickEvent.eventName, {
      detail: data,
      cancelable: false,
    });
  }
}

/**
 * ProductChangeVariant
 */
export type ProductChangeVariantData = {
  product: Storefront.Product;
  variant: Storefront.ProductVariant;
};

export class ProductChangeVariantEvent extends CustomEvent<ProductChangeVariantData> {
  static eventName = 'once-product:changeVariant';

  constructor(data: ProductChangeVariantData) {
    super(ProductChangeVariantEvent.eventName, {
      detail: data,
      cancelable: false,
    });
  }
}
